import request from "@/utils/request";
import { encryptByDES } from '@/utils/3DES.js'
// 谷歌登录
export function googleLogin(data) {
  return request({
    url: '/google/verify/google',
    headers: {
      isToken: false,
	  isMallApi: true
    },
    transformRequest:[function(data){
    	return data
    }],
    method: 'post',
    data: encryptByDES(JSON.stringify(data))
  })
}

// 实名
export function getRealName(data) {
  return request({
    url: '/niceApi/getNiceApiUrl',
    headers: {
      isToken: false,
	  isMallApi: true
    },
    method: 'get',
	params: data
  })
}

// 查询优惠券
export function getVoucher(data) {
  return request({
    url: '/userVoucherInfo/getVoucher',
    headers: {
      isToken: false,
	  isMallApi: true
    },
    method: 'post',
    data: encryptByDES(JSON.stringify(data))
  })
}
// 支付
export function getPay(data) {
  return request({
    url: '/payerMax/pay',
    headers: {
      isToken: false
    },
    method: 'post',
    data: encryptByDES(JSON.stringify(data))
  })
}