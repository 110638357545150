<template>
	<!-- 盒子登录 -->
	<van-popup
	  v-model:show="showLogins"
	  round
	  :close-on-click-overlay="false"
	>
	<div class="loginBox" v-if="!isAccountLogin">
		<div class="titleTxt">{{ $t('login.thirdTitle') }}</div>
		<div class="loginBtn">
			<div class="btn" @click="handleGoogle" ref="loginBtn">
				<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-google" viewBox="0 0 16 16">
					<path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
				</svg>
				<span>{{ $t('login.googleLogin') }}</span>
			</div>
		</div>
		<div class="loginBtn" v-if="!isMobile && gameId != 0">
			<div class="btn" @click="handleAccountChange">
				<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
				  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
				</svg>
				<span>{{ $t('login.accountTitle') }}</span>
			</div>
		</div>
	</div>
	<div class="loginBox" style="padding: 0.3125rem 0.625rem;" v-else>
		<div class="titleTxt">{{ $t('login.accountTitle') }}</div>
		<van-form @submit="onSubmit">
		  <van-cell-group inset>
			  <van-field style="display: none;"
			    v-model="gameId"
			    name="gameId"
			    label="GameName"
			    placeholder="GameName"
			    :rules="[{ required: true, message: 'The game cannot be empty' }]"
			  />
			<van-field
			  v-model="username"
			  name="userName"
			  :label="$t('login.name')"
			  :placeholder="$t('login.name')"
			  :rules="[{ required: true, message: $t('login.nameTips') }]"
			/>
			<van-field
			  v-model="password"
			  type="password"
			  name="password"
			  :label="$t('login.pass')"
			  :placeholder="$t('login.pass')"
			  :rules="[{ required: true, message: $t('login.passTips') }]"
			/>
		  </van-cell-group>
		  <div style="margin: 16px;">
			<van-button round block type="primary" native-type="submit">
			  {{ $t('login.submitBtn') }}
			</van-button>
			<div class="van-hairline--bottom"></div>
			<div class="text-center otherLogin" @click="handleAccountChange">{{ $t('login.thirdTitle') }}</div>
		  </div>
		</van-form>
	</div>
	</van-popup>
</template>

<script setup>
	import { getCurrentInstance,onMounted,defineProps,ref,watch,computed, onUnmounted } from 'vue'
	import { useRoute,useRouter } from 'vue-router';
	import useUserStore from '@/store/modules/user'
	import useConfigStore from '@/store/modules/config'
	import { googleTokenLogin } from "vue3-google-login";
	import { googleLogin, testLogin } from '@/api/gm.js'
	import { getVoucher, getPay } from '@/api/game'
	const { proxy } = getCurrentInstance();
	const props = defineProps({
		showLogin: {
			type: Boolean,
			default: false
		},
		gameid: {
			type: Number,
			default: 0
		}
	})
	const emits = defineEmits(['loginSuccess'])
	const isAccountLogin = ref(false)
	const username = ref('')
	const password = ref('')
	const platformId = ref(useUserStore().platformId)
	const googleId = ref(useConfigStore().googleId)
	const showLogins = computed(() => {
		return props.showLogin
	})
	const gameId = computed(() => {
		return props.gameid
	})
	const isMobile = computed(() => {
		return useConfigStore().isMobile
	})
	onMounted(() => {
	})
	const handleGoogle = () => {
		googleTokenLogin().then((response) =>{
			console.log(response)
			googleLogin({
				clientId: googleId.value,
				identityToken: response.access_token
			}).then((res) => {
				console.log(res)
				useUserStore().setInfo(res.data).then((datas) => {
					// logEvent(analytics, 'login_gm', { des: '盒子登录' })
					proxy.$toast('Success')
					emits('loginSuccess')
				})
			})
		})
	}
	const handleAccountChange = () => {
		isAccountLogin.value = !isAccountLogin.value
	}
	const onSubmit = (values) => {
		testLogin(values).then((res) => {
			console.log(res)
			useUserStore().setInfo(res.data).then((datas) => {
				proxy.$toast('Success')
				emits('loginSuccess')
			})
		})
	}
</script>

<style lang="less" scoped>
	.loginBox{
		width: 100%;padding: 0.3125rem 1rem 1.875rem;box-sizing: border-box;
		max-width: 20rem;min-width: 18.75rem;
		.titleTxt{
			text-align: center;margin: 0.9375rem 0 1.125rem;font-size: 1.125rem;
		}
		.loginBtn{
			width: 100%;display: flex;justify-content: space-between;
			a,div.btn{
				display: inline-flex;align-items: center;justify-content: center;
				box-sizing: border-box;border-radius: 25px;
				width: 100%;padding: 12px 10px;color: rgba(18,18,18,0.75);
				border: 1px solid rgba(18,18,18,0.75);font-size: 15px;cursor: pointer;
				transition: 0.3s;flex-wrap: wrap;
				span{
					margin-left: 12px;
				}
				&:hover{
					background: rgb(18,18,18);color: #fff;
				}
			}
		}
		.otherLogin{
			font-size: 0.75rem;line-height: 1.25rem;margin-top: 0.75rem;
			&:hover{
				text-decoration: underline;cursor: pointer;
			}
		}
		.loginBtn + .loginBtn{
			margin-top: 16px;
		}
	}
</style>