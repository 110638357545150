<template>
  <div>
    <template v-for="(item, index) in options">
      <template v-if="values.includes(item.value)">
        <span
          v-if="item.elTagType == 'default' || item.elTagType == ''"
          :key="item.value"
          :index="index"
          :class="item.elTagClass"
        >{{ item.label }}</span>
        <van-tag
          v-else
          :disable-transitions="true"
          :key="item.value + ''"
          :index="index"
          :type="item.elTagType"
          :class="item.elTagClass"
        >{{ item.label }}</van-tag>
      </template>
    </template>
  </div>
</template>

<script>
	export default {
		props: {
		  options: {
		    type: Array,
		    default: null,
		  },
		  value: [Number, String, Array],
		},
		computed: {
		  values() {
		    if (this.value !== null && typeof this.value !== 'undefined') {
		      return Array.isArray(this.value) ? this.value : [String(this.value)];
		    } else {
		      return [];
		    }
		  },
		},
	}
</script>

<style scoped>
.van-tag + .van-tag {
  margin-left: 10px;
}
</style>