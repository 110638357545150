import request from '@/utils/request'

// 查询字典数据详细
export function getData(dictCode) {
  return request({
    url: '/system/dict/data/' + dictCode,
	headers: {
	  isOpenApi: true,
	  isToken: false
	},
    method: 'get'
  })
}
// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
  return request({
    url: '/system/dict/data/type/' + dictType,
	headers: {
	  isOpenApi: true,
	  isToken: false
	},
    method: 'get'
  })
}