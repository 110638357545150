import cache from '@/plugins/cache'
import { defineStore } from 'pinia'
import { getUrlParam } from '@/utils/common'
const useUserStore = defineStore(
  'user',
  {
    state: () => ({
	  userSign: cache.session.get('userSign') ? cache.session.get('userSign') : '',
	  userToken: cache.session.get('userToken') ? cache.session.get('userToken') : '',
	  userInfo: cache.session.getJSON('userInfo') ? cache.session.getJSON('userInfo') : '',
	  roleInfo: cache.session.getJSON('roleInfo') ? cache.session.getJSON('roleInfo') : '',
	  orderInfo: cache.session.getJSON('orderInfo') ? cache.session.getJSON('orderInfo') : '',
	  platformId: cache.session.get('platformId') ? cache.session.get('platformId') : 2005,//pc平台
	  ostype: cache.session.get('ostype') ? cache.session.get('ostype') : 3,//终端，1安卓，2IOS，3H5，100全端
	  screentype: cache.session.get('screentype') ? cache.session.get('screentype') : 1,//游戏屏幕方向，1横屏，2竖屏
	  isweb: cache.session.get('isweb') ? cache.session.get('isweb') : 0,//1web端，0微端
    }),
    actions: {
      // 存用户信息
      setInfo(data) {
          return new Promise((resolve, reject) => {
			this.userInfo = data;
			this.userToken = data.token;
			cache.session.setJSON('userInfo',data)
			cache.session.set('userToken',data.token)
			resolve(data)
          })
      },
	  // 存游戏加密信息
	  setSign(data) {
	      return new Promise((resolve, reject) => {
			  if(data.indexOf('super_token') > 0){
				  this.userSign = getUrlParam(data,'super_token')
			  }else{
				  this.userSign = data;
			  }
			cache.session.set('userSign',this.userSign)
			resolve()
	      })
	  },
	  // 存角色信息
	  setRoleInfo(data) {
	      return new Promise((resolve, reject) => {
				this.roleInfo = data;
				cache.session.setJSON('roleInfo',data)
				resolve()
	      })
	  },
	  // 存订单信息
	  setOrderInfo(data) {
	      return new Promise((resolve, reject) => {
				this.orderInfo = data;
				cache.session.setJSON('orderInfo',data)
				resolve()
	      })
	  },
	  // 清除订单信息
	  clearOrder(){
		  this.orderInfo = '';
		  cache.session.remove('orderInfo')
	  },
	  // 存微端信息
	  setIsweb(data) {
	      return new Promise((resolve, reject) => {
				this.isweb = data;
				cache.session.set('isweb',data)
				resolve()
	      })
	  },
	  // 存屏幕方向
	  setScreenType(data) {
	      return new Promise((resolve, reject) => {
				this.screentype = data;
				cache.session.set('screentype',data)
				resolve()
	      })
	  },
	  
	  close(){
		  return new Promise((resolve, reject) => {
			    this.userToken = '';
				this.userInfo = '';
				this.userSign = '';
				this.roleInfo = '';
				cache.session.remove('userSign')
				cache.session.remove('roleInfo')
				cache.session.remove('userInfo')
				cache.session.remove('userToken')
				resolve()
		  })
	  }
    }
  })

export default useUserStore
