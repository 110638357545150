<template>
	<van-config-provider theme="light">
		<router-view v-if="isRouterActive"></router-view>
	</van-config-provider>
</template>

<script setup name="App">
	import { ref,provide,nextTick,onMounted,onBeforeMount, getCurrentInstance } from 'vue'
	import { useRouter } from 'vue-router'
	import useUserStore from '@/store/modules/user'
	import useConfigStore from '@/store/modules/config'
	import { decryptByDES } from '@/utils/3DES.js'
	
	const { proxy } = getCurrentInstance(); 
	const router = useRouter();
	const userStore = useUserStore();
	const configStore = useConfigStore();
	
	const isRouterActive = ref(true)
	provide('reload', () => {
	  isRouterActive.value = false
	  nextTick(() => {
	    isRouterActive.value = true
	  })
	})
	const initSW = () => {
		console.log(navigator)
	  if ('serviceWorker' in navigator) {
	    window.addEventListener('load', () => {
	      navigator.serviceWorker
	        .register('../service-worker.js')
	        .then(registration => {
	          console.log('SW registered: ',registration);
	        })
	        .catch(registrationError => {
	          console.log('SW registration failed: ',registrationError);
	        });
	    });
	  }
	};
	onMounted(()=>{
		isMobile()
	})
	onBeforeMount(()=>{
		initSW()
		window.CallbackFromIOS = CallbackFromIOS;
		window.CallbackFromAndroid = CallbackFromAndroid;
	})
	const isMobile = () => {
	  	let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
	  	console.log(flag)
		useConfigStore().setIsMobile(flag)
	}
	const CallbackFromIOS = (str) => {
		let jsonStr = proxy.$Base64.decode(str);
		let obj = JSON.parse(jsonStr);
		handleSwitch(obj);
	}
	const CallbackFromAndroid = (str) => {
		let jsonStr = proxy.$Base64.decode(str);
		let obj = JSON.parse(jsonStr);
		handleSwitch(obj);
	}
	const orderDecode = (str) => {
		let dataStr = decryptByDES(str);
		let jsonObj = JSON.parse(dataStr);
		userStore.setOrderInfo(jsonObj)
		console.log(jsonObj)
	}
	const handleSwitch = (obj) => {
		console.log(obj)
		switch(obj.action){
			case 'getSuperUserInfo'://初始化获取角色信息
				console.log(obj.data)
				userStore.setSign(obj.data).then(() => {
					userStore.setRoleInfo({gameId:obj.gameId})
				});
				break;
			case 'onPayMsgInfo'://订单信息
				console.log(obj.data)
				orderDecode(obj.data)
				break;
			default:
				break;
		}
	}
	
	
</script>

<style lang="less">
	body{
		background: #efefef;;
	}
</style>
