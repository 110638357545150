<template>
	<div ref="mainBody" class="mainBody" :class="[!isMobile?'landscape':'default']">
		<template v-if="!isMobile">
			<div id="nav">
			  <div class="header">
				<div class="logoImg">
					<router-link id="google-home-event-btn" to="/"><img src="@/assets/images/common/gm_icon.png" /></router-link>
					<p>{{ $t('home.slogans') }}</p>
				</div>
			    <div class="tabList">
			      <router-link to="/home/recommend">{{ $t('menu.home') }}</router-link>
			      <router-link to="/home/news">{{ $t('menu.news') }}</router-link>
			      <router-link to="/coupon">{{ $t('menu.coupon') }}</router-link>
			      <router-link to="/pcdownload">{{ $t('menu.pcdownload') }}</router-link>
			    </div>
				<div style="display: flex;align-items: center;flex-shrink: 0;height: 100%;margin-right: 10px;">
					<div id="google-searchBox-event-btn" class="searchBox" v-if="current != 'search'">
						<van-search
						  v-model="searchKey"
						  shape="round"
						  :placeholder="$t('common.searchkey')"
						  @click-input="onSearch"
						>
						</van-search>
					</div>
					<div class="accountBox">
						<div class="avatarBox">
							<div class="avatar">
								<img v-if="userInfo.picture" :src="userInfo.picture" :onerror="imgError">
								<img v-else src="~@/assets/images/common/avatar_temp.png" />
							</div>
							<div class="btns" v-if="userToken" @click="handleLogout">[{{ $t('personal.loginOut') }}]</div>
							<div class="btns" v-else @click="openLogin = true">[{{ $t('personal.loginBtn') }}]</div>
						</div>
						<div class="tips">
							{{ $t('home.tips') }}
						</div>
						<div class="accountInfo" v-if="userToken">
							<van-cell center :label="`${$t('personal.googleEmail')}：${userInfo.email}`" is-link to="/home">
								<template #title>
									<span class="titleLabel">{{ $t('personal.platId') }}：</span>
									<span class="titleVal">{{userInfo.superUserId}}</span>
								  </template>
							  </van-cell>
							  <div class="lineBox">
								<div class="coinLine">
									<i class="coinIcon"></i>
									<p>{{ $t('personal.platCoin') }}：{{userInfo.platCoin}}</p>
									<div class="rechargeBtn" @click="handleRecharge">{{ $t('pay.recharge') }}</div>
								</div>
							  </div>
						</div>
					</div>
				</div>
			  </div>
			</div>
			<div class="topBanner" v-if="current == 'coupon' || current == 'news' || current == 'pcdownload'">
				<div class="bigTitle" v-if="current == 'coupon'">
					<h1>{{ $t('menu.coupon') }}</h1>
					<p><strong>WELFARE DISCOUNTS</strong></p>
				</div>
				<div class="bigTitle" v-if="current == 'news'">
					<h1>{{ $t('menu.news') }}</h1>
					<p><strong>GAME INFORMATION</strong></p>
				</div>
				<div class="bigTitle" v-if="current == 'pcdownload'">
					<h1>{{ $t('menu.pcdownload') }}</h1>
					<p><strong>PC SETUP INSTRUCTIONS</strong></p>
				</div>
			</div>
		</template>
		<template v-else>
			<van-notice-bar color="#333" background="#fff" left-icon="https://www.gm321321.com/gm_icon.png" v-if="showSlogan()">
			  {{ $t('home.slogans') }}
			</van-notice-bar>
		</template>
		<AppMain />
		<template v-if="showTabbar() && isMobile">
			<van-tabbar v-model="current">
			  <van-tabbar-item name="home" to="/home" icon="home-o">{{ $t('menu.home') }}</van-tabbar-item>
			  <van-tabbar-item name="coupon" to="/coupon" icon="coupon-o">{{ $t('menu.coupon') }}</van-tabbar-item>
			  <van-tabbar-item name="personal" to="/personal" icon="manager-o">{{ $t('menu.personal') }}</van-tabbar-item>
			</van-tabbar>
		</template>
		<template v-if="!isMobile">
			<div class="navBox" :class="showNav ? 'active' : 'disable'">
				<div class="navClose" @click="showNav = true"></div>
				<div class="navOpen" @click="showNav = false">
					<!-- <img src="~@/assets/images/common/nav_logo.png" /> -->
					<img class="imgTitle" src="~@/assets/images/common/nav_deskf.png" />
					<!-- <p class="kftext">GM20238</p> -->
					<div class="linkBtns">
						<div class="items"><router-link to="/recharge"><img src="~@/assets/images/common/nav_paycenterbtn.png" /></router-link></div>
						<div class="items" @click="toStoreUrl"><a href="javascript:;"><img src="~@/assets/images/common/nav_storebtn.png" /></a></div>
					</div>
				</div>
				
			</div>
		</template>
		<template v-if="isMobile">
			<div class="navMenu">
				<div class="navItem" :class="isLoaded == 1 ?'':'active'" @click="toStore">
					<a href="javascript:;"><img class="navIcon" src="~@/assets/images/common/navicon_shop.png" /></a>
				</div>
				<div class="navItem">
					<router-link to="/personal"><img class="navIcon" src="~@/assets/images/common/navicon_personal.png" /></router-link>
				</div>
			</div>
		</template>
		<template v-if="!isMobile">
			<div id="footer">
			    <div class="footerBox">
					<div class="listItem">
							<!-- 公司介绍 -->
							<p><router-link to="/pages/about">{{ $t('footer.aboutus') }}</router-link></p>
							<!-- 客服支持 -->
							<p><router-link to="/pages/contact">{{ $t('footer.contact') }}</router-link></p>
							<!-- 隐私政策 -->
							<p><router-link to="/clauseService">{{ $t('footer.service') }}</router-link></p>
							<!-- 退款协议 -->
							<p><router-link to="/pages/refund">{{ $t('footer.refund') }}</router-link></p>
							<!-- 用户协议 -->
							<p><router-link to="/clauseUser">{{ $t('footer.userService') }}</router-link></p>
					</div>
					
					<div class="footItem">
						<div class="item">
							<!-- 公司名字,营业执照号 -->
							<p>{{ $t('footer.companyLabel') }}：{{ $t('footer.companyValue') }}，{{ $t('footer.licenseNumLabel') }}：{{ $t('footer.licenseNumValue') }}</p>
							<!-- 代表名 -->
							<p>{{ $t('footer.legalPersonLabel') }}：{{ $t('footer.legalPersonValue') }}</p>
							<!-- 邮箱,电话号码 -->
							<p>{{ $t('footer.emailLabel') }}：{{ $t('footer.emailValue') }}，{{ $t('footer.telLabel') }}：{{ $t('footer.telValue') }}</p>
						</div>
						<div class="item">
							<!-- 银行账号 -->
							<!-- <p>{{ $t('footer.bankLabel') }}：{{ $t('footer.bankValue') }}</p> -->
							<!-- 邮购业务编号 -->
							<p>{{ $t('footer.businessNumLabel') }} ：{{ $t('footer.businessNumValue') }}</p>
							<!-- 地址 -->
							<p>{{ $t('footer.addressLabel') }}：{{ $t('footer.addressValue') }}</p>
							<p>{{ $t('footer.support') }}</p>
						</div>
						
						<div class="version" v-if="showLuoliVersion">
							<div class="infos">
								<ul>
									<li>이용등급</li> <li>청소년이용불가</li>
									<li>등급분류 일자</li> <li>2024년 8월 1일</li>
									<li>등급분류 번호</li> <li>제 CC-OM-240801-001 호</li>
									<li>제작`배급업 신고번호</li> <li>제2023-022호</li>
								</ul>
							</div>
							<img class="verIcon" src="~@/assets/images/common/19+.png" />
							<img class="verIcon" src="~@/assets/images/common/GRAC.png" />
						</div>
					</div>
					
			    </div>
			</div>
		</template>
		<Login :showLogin="openLogin" @loginSuccess="loginSuccess" />
		<!-- <van-button style="z-index: 9999;left: 50px;position: fixed;top: 8px;" 
			size="small" 
			type="primary" 
			@click="changeLang()"
		>切换语言</van-button> -->
	</div>
</template>

<script setup>
import { ref,reactive,inject,defineAsyncComponent,onMounted,getCurrentInstance,watch,computed } from 'vue'
import { useRoute,useRouter,onBeforeRouteUpdate } from 'vue-router'
import { menuGift, privilegePack } from '@/api/index.js'
import { gmInfo } from '@/api/gm.js'
import { useI18n } from "vue-i18n"
import useUserStore from '@/store/modules/user'
import useConfigStore from '@/store/modules/config'
import Login from '@/components/login'

const AppMain = defineAsyncComponent(() => import("./components/AppMain.vue"));
const { proxy } = getCurrentInstance();
const route = useRoute();
const router = useRouter();
const showNav = ref(true)
const active = ref('home');
const current = ref(route.meta.activeMenu);
const isweb = ref(0);//是否web端
const openLogin = ref(false)
const isMobile = computed(() => {
	return useConfigStore().isMobile
})
const userToken = computed(() => {
	return useUserStore().userToken || ''
})
const userInfo = computed(() => {
	return useUserStore().userInfo
})
const isLoaded = computed(() => {
	return useConfigStore().isLoaded
})
const showLuoliVersion = computed(() => {
	return useConfigStore().isLuoli
})
const mainBody = ref(null);

const searchKey = ref('')
const isLoading = ref(false);
const reload = inject('reload');
const params = ref({})
const testAccount = ref(['364431168@qq.com','y1287359820@gmail.com'])
onBeforeRouteUpdate((to) => {
	current.value = to.meta.activeMenu;
	console.log(current.value)
});

const showTabbar = () => {
	let haveTabbar = ['home','coupon','search','personal','pcdownload']
	if(haveTabbar.indexOf(route.meta.activeMenu) > -1){
		return true
	}
	return false
}
const showSlogan = () => {
	let haveSlogan = ['payment','paymentTest']
	if(haveSlogan.indexOf(route.meta.activeMenu) > -1){
		return false
	}
	return true
}
const onSearch = () => {
	useConfigStore().setSearch(0)
	router.push({
	  name:'Search',
	  params: params.value
	});
}
onMounted(()=>{
	let queryParams = router.currentRoute.value.query;
	params.value = Object.assign({},queryParams)
	isweb.value = queryParams.isweb || 0
	useUserStore().setIsweb(isweb.value)
})
const test = () => {
	if(userInfo.value && testAccount.value.includes(userInfo.value.email)){
		return true
	}else{
		return false
	}
}
// const init = () => {
// 	proxy.commonPostMsg({ action: 'webDidLoad', data: refreshId })
// }
const toStoreUrl = () => {
	let url = 'https://pay.gm321321.com/'
	let rObj = router.currentRoute.value
	if(rObj.name == 'Game'){
		url = url + 'products/detail/' + rObj.params.id
	}
	window.location.href = url
}
const toStore = () => {
	useConfigStore().setIsLoaded(1)
	toStoreUrl()
}
const { locale } = useI18n();
const changeLang = () => {
	let lang = locale.value == 'kr' ? 'zh' : 'kr';
	locale.value = lang;
	reload()
}
const loginSuccess = () => {
	console.log('登录成功')
	openLogin.value = false
	// gmInfo().then((res) => {
	// 	console.log(res)
	// })
}
const handleLogout = () => {
	useUserStore().close().then(() => {
		proxy.$toast('Success')
	})
}
const handleRecharge = () => {
	router.push({
	  name:'Recharge',
	  params: params.value
	});
}
const imgError = () => {
	var img = event.srcElement;
	img.src = require('@/assets/images/common/avatar_temp.png');
	img.onerror = null;
}
watch(userInfo, (newVal, oldVal) => {
	console.log('用户信息更新')
})
</script>

<style lang="less" scoped>
	.version{
		width: 458px;display: flex;align-items: center;justify-content: flex-start;
		.verIcon{
			width: 79px;margin-left: 5px;
		}
		.infos{
			width: 330px;
			ul{
				width: 100%;display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;
				li{
					width: 42%;box-sizing: border-box;padding: 0 8px;
					border-left: 1px solid #e0e8ea;
					border-bottom: 1px solid #e0e8ea;
					font-size: 12px;
					&:first-child{
						border-top: 1px solid #e0e8ea;
					}
					&:nth-child(2){
						border-top: 1px solid #e0e8ea;
					}
					&:nth-child(2n){
						border-right: 1px solid #e0e8ea;width: 58%;
					}
				}
			}
		}
	}
	.navMenu{
		position: fixed;right: 0.625rem;bottom: 10%;z-index: 11;
		width: 2.875rem;height: 6.875rem;
		display: flex;align-items: center;justify-content: center;flex-wrap: wrap;
		.navItem{
			width: 100%;
			&.active{
				position: relative;
				&::after{
					content: '';
					position: absolute;left: 0;top: 0;
					display: block;width: 0.75rem;height: 0.75rem;
					background: url('~@/assets/images/common/icon_new.png') no-repeat center;background-size: 100%;
				}
			}
			.navIcon{
				width: 100%;
			}
		}
	}
	.navBox{
		position: fixed;right: 0;top: 20%;z-index: 11;
		width: 180px;height: 420px;overflow: hidden;cursor: pointer;
		display: flex;align-items: center;justify-content: flex-end;transition: ease-out 0.6s;
		.navOpen{
			width: 100%;height: 100%;background: url('~@/assets/images/common/nav_openbg.png') no-repeat center;
			background-size: 100%;
			position: absolute;right: 0;top: 0;transition: ease-out 1s;
			padding: 60px 10px 30px;box-sizing: border-box;
			display: flex;align-items: center;justify-content: center;flex-wrap: wrap;
			.kftext{
				color: #fff;font-weight: 600;
			}
		}
		.imgTitle{
			position: absolute;top: 36px;left: 50%;transform: translate(-50%,0);
		}
		.linkBtns{
			position: absolute;bottom: 28px;right: 0;transition: ease-out 1s;width: 100%;
			padding: 0 14px;box-sizing: border-box;
			.items{
				width: 100%;
				a{ display: inline-block; }
				img{
					width: 100%;
				}
			}
		}
		.navClose{
			width: 50px;height: 220px;background: url('~@/assets/images/common/nav_closebg.png') no-repeat center;
			background-size: 100%;
			transition: ease-out 1s;
			position: absolute;right: 200px;top: 50%;transform: translate(0,-50%);
			transition: ease-out 1s;
		}
		&.active{
			right: 0;
			.navClose{
				// display: none;
				right: 180px;
			}
			.navOpen{
				// display: block;
				right: 0;
			}
		}
		&.disable{
			.navClose{
				// display: block;
				right: 0;
			}
			.navOpen{
				// display: none;
				right: -180px;
			}
		}
		
	}
	.accountBox{
		position: relative;flex-shrink: 0;height: 100%;
		display: flex;align-items: center;
		justify-content: center;
		flex-direction: column;
		&:hover{
			.accountInfo{
				display: block;
			}
		}
		.avatarBox{
			display: flex;align-items: center;
			.avatar{
				width: 42px;height: 42px;border: 0.125rem solid #D5C081;border-radius: 50%;margin-right: 10px;overflow: hidden;
				img{
					width: 100%;
				}
			}
			.btns{
				color: #D5C081;font-size: 14px;cursor: pointer;
			}
		}
		.tips{
			font-size:12px;
			color: white;
			margin-top: 0.2rem;
		}
		.accountInfo{
			position: absolute;right: 0;top: 80px;z-index: 1111;width: 300px;
			background: rgba(13,13,13,0.85);
			display: none;transition: all 0.3s ease-in-out;
			.lineBox{
				padding: 0 16px 16px;box-sizing: border-box;margin: 0;
			}
			.coinLine{
				display: flex;align-items: center;justify-content: center;
				padding: 12px;
				background: url('~@/assets/images/common/line_bg.png') no-repeat center;
				background-size: 100%;
				.coinIcon{
					display: inline-block;width: 20px;height: 20px;
					background: url('~@/assets/images/common/coin_icon.png') no-repeat center;
					background-size: 100%;flex-shrink: 0;margin-right: 5px;
				}
				p{
					flex: 1;font-size: 14px;
				}
				.rechargeBtn{
					flex-shrink: 0;
					font-size: 14px;cursor: pointer;
					background: linear-gradient(to right,#51BAC0,#85D6DA);color: #fff;
					padding: 3px 20px;border-radius: 5px;
				}
			}
			/deep/.van-cell{
				background: transparent;
				line-height: 24px;
				.titleLabel,.titleVal{
					font-size: 16px;color: #D5C081;
				}
				.van-cell__right-icon{
					color: #D5C081;
				}
				&::after{
					border-bottom: none;
				}
			}
		}
	}
	.topBanner{
		width: 100%;height: 440px;
		background: url('~@/assets/images/common/top_bg.jpg') no-repeat center;
		background-size: auto 100%;
		display: flex;align-items: center;
		.bigTitle{
			width: 1122px;margin: 0 auto;font-style: italic;
			h1{
				font-size: 90px;
			}
			p{
				font-size: 56px;
			}
		}
	}
	.mainBody{
		width: 100%;height: 100vh;position: relative;
		margin: 0 auto;background: #fff;
		.viewBox{
			width: 100%;
			position: absolute;left: 50%;top: 50%;
			transform: translate(-50%,-50%);
		}
		&.landscape{
			max-width: 100%;height: auto;min-height: 100vh;
			background: #f3f3f3;
		}
		&.default{
			max-width: 28.125rem;
			.viewBox{
				padding-bottom: 5rem;
			}
			/deep/ .van-tabbar--fixed{
				position: absolute;
			}
		}
		
		
	}
</style>