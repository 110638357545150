// import { createI18n } from 'vue-i18n/dist/vue-i18n.cjs.js'
import { createI18n } from 'vue-i18n'
import zh from './zh'
import kr from './kr'

const i18n = createI18n({
	legacy: false,
	locale: 'kr',
	messages: {
		zh: zh,
		kr: kr
	}
})

export default i18n