import { showToast, showNotify, showDialog } from 'vant'
import { useDict } from '@/utils/dict'
import { formatNumstr,formatBr,parseTime,iosPostMsg,androidPostMsg,commonPostMsg,sdkPay,isJsonString } from '@/utils/common'
import { Partten } from "@/utils/partten";
import { Base64 } from "js-base64";
import i18n from '@/lang/index.js'
import cache from './cache'
// 字典标签组件
import Navbar from '@/components/Navbar'
import DictTag from '@/components/DictTag'

export default {
	install: (app) => {
		app.config.globalProperties.$i18n = i18n
		app.config.globalProperties.$toast = showToast
		app.config.globalProperties.$notify = showNotify
		app.config.globalProperties.$dialog = showDialog
		app.config.globalProperties.$Base64 = Base64
		app.config.globalProperties.$cache = cache
		app.config.globalProperties.useDict = useDict
		app.config.globalProperties.partten = Partten
		app.config.globalProperties.parseTime = parseTime
		app.config.globalProperties.formatBr = formatBr
		app.config.globalProperties.formatNumstr = formatNumstr
		app.config.globalProperties.isJsonString = isJsonString
		app.config.globalProperties.sdkPay = sdkPay
		app.config.globalProperties.commonPostMsg = commonPostMsg
		app.config.globalProperties.iosPostMsg = iosPostMsg
		app.config.globalProperties.androidPostMsg = androidPostMsg
		app.component('Navbar', Navbar)
		app.component('DictTag', DictTag)
	}
}