<template>
	<van-nav-bar
		:placeholder="true"
		@click-left="onClickLeft"
		@click-right="onClickRight"
	>
		<template #left>
			<div class="titlebox" :class="{'br100':leftIcon == 'arrow-left'}">
				<van-icon v-if="leftIcon" :name="leftIcon" :size="leftIcon == 'arrow-left' ? 16 : 20" />
				<span v-if="leftText">{{ leftText }}</span>
			</div>
		</template>
		<template #title>
			<div class="subTitle">
				<van-icon v-if="titleIcon" :name="titleIcon" size="16" />
				<span v-if="title">{{ title }}</span>
			</div>
		</template>
		<template #right>
			<div class="subTitlebox">
				<span class="back" v-if="isback" @click="backBtn">返回</span>
				<van-icon v-if="rightIcon" :name="rightIcon" size="20" />
				<van-badge :show-zero="false" :content="isNews > 0 ? 'new' : '0'">
					<span v-if="rightText">{{ rightText }}</span>
				</van-badge>
			</div>
		</template>
	</van-nav-bar>
</template>

<script>
	import { ref } from 'vue'
	import { useRouter } from 'vue-router';
	export default {
		props:{
			title: String,
			titleIcon: String,
			leftIcon: String,
			leftText: String,
			rightIcon: String,
			rightText: String,
			isback: Boolean,
			isNews: Number
		},
		setup(props,context) {
			const router = useRouter();
			const backBtn = () => {
				// if(history.state.back.indexOf('super/mall') < 0){
					history.back()
				// }
			}
			const onClickLeft = () => {
				if(context.attrs.onOnClickLeft){
					context.emit('onClickLeft')
				}else{
					backBtn()
				}
			}
			const onClickRight = () => {
				if(context.attrs.onOnClickRight){
					context.emit('onClickRight')
				}else{
					backBtn()
				}
			};
			return {
				backBtn,
				onClickLeft,
				onClickRight
			};
		}
	}
</script>

<style lang="less" scoped>
	.titlebox{
		font-size: 1.5rem;font-family: "font2";
		box-sizing: border-box;color: #55353D;
		margin-left: 0.75rem;
	}
	.subTitlebox{
		font-weight: 600;
		span{
			display: inline-block;color: #55353D;position: relative;
			margin-left: 0.625rem;padding-left: 0.625rem;
			&::before{
				display: block;position: absolute;content:'';
				width: 1px;height: 60%;top: 15%;left: 0;
				background: linear-gradient(to bottom,#FFE9B3, #5C5C5C, #FFE9B3);
			}
			&.back{
				&::before{
					display: none;
				}
			}
		}
	}
	/deep/.van-nav-bar__title{
		flex: 1;text-align: left;padding-left: 1.75rem;
		.subTitle{
			display: flex;align-items: center;
			color: #55353D;
			.van-icon{
				margin-right: 0.3125rem;
			}
			span{
				font-size: 0.875rem;font-family: "font1";
			}
		}
	}
</style>
