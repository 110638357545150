import axios from 'axios'
// import store from '@/store'
import cache from '@/plugins/cache'
import useUserStore from '@/store/modules/user'
import errorCode from '@/utils/errorCode'
import { showToast } from 'vant';

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 10000
})
// request拦截器
service.interceptors.request.use(config => {
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  const isOpenApi = (config.headers || {}).isOpenApi === true
  const isMallApi = (config.headers || {}).isMallApi === true
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (!isToken) {
  	  const userStore = useUserStore();
  	  if(userStore.userSign){
  		  config.headers['SecretParameters'] = userStore.userSign 
  	  }
	  if(userStore.userToken){
		  config.headers['Identification'] = userStore.userToken 
	  }
  }
  if(isOpenApi){
    config.baseURL = process.env.VUE_APP_OPEN_API;
  }
  if(isMallApi){
    config.baseURL = process.env.VUE_APP_MALL_API;
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?';
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName];
      var part = encodeURIComponent(propName) + "=";
      if (value !== null && typeof(value) !== "undefined") {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            if (value[key] !== null && typeof (value[key]) !== 'undefined') {
              let params = propName + '[' + key + ']';
              let subPart = encodeURIComponent(params) + '=';
              url += subPart + encodeURIComponent(value[key]) + '&';
            }
          }
        } else {
          url += part + encodeURIComponent(value) + "&";
        }
      }
    }
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
	if(typeof(code) == 'string' && code.indexOf('SUCCESS') > -1){
		return res.data
	} else if (code !== 200) {
	  showToast(msg)
	  res.data.msg = msg
      return Promise.reject(res.data)
    } else {
      return res.data
    }
  },
  error => {
    console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "Network Error";
    }
    else if (message.includes("timeout")) {
      message = "timeout";
    }
    else if (message.includes("Request failed with status code")) {
      // message = "系统接口" + message.substr(message.length - 3) + "异常";
      message = "Request failed " + message.substr(message.length - 3);
    }
	showToast(message)
	error.msg = message
    return Promise.reject(error)
  }
)

export default service
