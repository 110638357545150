// 请求成功状态
export const successCode = 200;//成功

export const MENU_DISCOUNT = [
	{name:'限时礼包',id:'2'},
	// {name:'赞助礼包',id:'4'},
	{name:'限购礼包',id:'7'},
	{name:'超值礼包',id:'18'},
	{name:'战令基金',id:'23'},
]
export const MENU_MALL = [
	{name:'资源阁',id:'11'},
	{name:'BUG万抽',id:'15'},
	{name:'福利转盘',id:'12'},
	{name:'超级转盘',id:'13'},
	// {name:'赞助特权',id:'14'}
]
export const MENU_WELFARE = [
	{name:'特权豪礼',id:'3',subMenu: true},
	// {name:'七日豪礼',id:'5'},
	{name:'累充豪礼',id:'6'},
	{name:'在线福利',id:'8'},
	{name:'无限刷充',id:'20'},
	{name:'问卷调查',id:'21'},
	{name:'客服中心',id:'22'},
	{name:'天天领福利',id:'9'},
]
export const MENU_ALL = [
	{name:'客服中心',id:'22'},
	{name:'天天领福利',id:'9'},
	{name:'在线福利',id:'8'},
	{name:'限时礼包',id:'2'},
	{name:'超值礼包',id:'18'},
	{name:'资源阁',id:'11'},
	{name:'超级转盘',id:'13'},
	{name:'福利转盘',id:'12'},
	{name:'累充豪礼',id:'6'},
	// {name:'七日豪礼',id:'5'},
	{name:'特权豪礼',id:'3',subMenu: true},
	{name:'无限刷充',id:'20'},
	{name:'限购礼包',id:'7'},
	{name:'战令基金',id:'23'},
	// {name:'赞助礼包',id:'4'},
	{name:'BUG万抽',id:'15'},
	{name:'问卷调查',id:'21'},
	// {name:'赞助特权',id:'14'}
	// {name:'节日活动',id:''}
	// {name:'幸运翻牌',id:''}
]

// 问题大类（对应字典problem_type）
export const problemTypeRebate = 1;//返利问题
export const problemTypeActivity = 2;//活动福利
export const problemTypeShift = 3;//转游福利
export const problemTypeOthers = 4;//其他问题
export const problemTypeAbnormal = 5;//游戏异常
export const problemTypeLogin = 6;//登陆问题
export const problemTypeRecharge = 7;//充值问题
export const problemTypeReport = 8;//举报封禁

// 上传文件类型（1文件2视频3图片）
export const fileTypeDoc = 1;
export const fileTypeVideo = 2;
export const fileTypeImg = 3;

// 问题进度（对应字典problem_status）
export const problemStatusWait = 0;//待处理
export const problemStatusBegin = 1;//已受理
export const problemStatusEnd = 2;//已处理

export const firebaseConfig = {
	  apiKey: "AIzaSyBgAzRfI0K3AuxStLYs6mXxqVzRRCMFPVo",
	  authDomain: "gm-store-a970e.firebaseapp.com",
	  projectId: "gm-store-a970e",
	  storageBucket: "gm-store-a970e.appspot.com",
	  messagingSenderId: "856953757193",
	  appId: "1:856953757193:web:e9b7d33a846af6b96be2d8",
	  measurementId: "G-B28GXKTNB5"
	}